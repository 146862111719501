import { ErrorHandlerMixin, STUCK_CLAIMS_TIME_LOG } from "@kraftheinz/common";
import _ from "lodash";
import { n as normalizeComponent } from "./index.js";
import "vue";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Time Log", "has-active": false, "has-filter-inactive": false, "has-action": false, "fetch-on-first-mount": false, "loading": _vm.isLoading }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("div", { staticClass: "d-flex flex-column w-100" }, [_c("div", { staticClass: "d-flex justify-content-between" }, [_c("div", { staticClass: "left-filter d-flex align-items-center" }, [_vm._v(" Country "), _c("select-input", { staticClass: "m-2 mr-4", staticStyle: { "min-width": "150px" }, attrs: { "data-source": _vm.listCountries, "source": "code", "source-label": "description", "placeholder": "Select Country", "value": !_vm.isAdmin ? _vm.selectedCountry : "All", "disabled": !_vm.isAdmin }, on: { "change": _vm.onCountryChange } }), _vm._v(" Date Range "), _c("date-range-picker", { staticClass: "ml-2", attrs: { "receive-value-on-clear": "" }, on: { "change": _vm.onDateRangeChange } })], 1), _c("div", { staticClass: "right-filter d-flex align-items-center" }, [_c("a-tooltip", { attrs: { "title": "Search by First Name, Last Name and Role" } }, [_c("keyword-filter", { staticClass: "m-2", attrs: { "field": "FirstName,LastName,Role", "placeholder": "Search by First Name, Last Name and Role" } })], 1)], 1)]), _c("div", { staticClass: "mb-2" }, [_vm._v("Total Site visitor Count: " + _vm._s(_vm.totalVisitors))])])];
  }, proxy: true }]) }, [_c("text-field", { key: "FirstName", attrs: { "title": "First Name", "data-index": "firstName", "sorter": true, "width": 250 } }), _c("text-field", { key: "LastName", attrs: { "title": "Last Name", "data-index": "lastName", "sorter": true, "width": 250 } }), _c("text-field", { key: "Role", attrs: { "title": "Role", "data-index": "role", "sorter": true, "width": 250 } }), _c("text-field", { key: "VisitCount", attrs: { "title": "Visit Count", "data-index": "visitCount", "sorter": true, "width": 250 } }), _c("text-field", { key: "Time", attrs: { "title": "Time Log", "data-index": "time", "parse": _vm.parseTime, "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
var TimeLog_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "TimeLog",
  inject: ["crud", "getSelectedCountry", "checkIsAdmin"],
  mixins: [ErrorHandlerMixin],
  data() {
    return {
      listCountries: [],
      isLoading: true
    };
  },
  computed: {
    totalVisitors() {
      return this.crud.getPagination().count;
    },
    selectedCountry() {
      return this.getSelectedCountry();
    },
    isAdmin() {
      return this.checkIsAdmin();
    }
  },
  async created() {
    this.getCountriesLists();
    if (!this.isAdmin) {
      this.crud.setFilter("Country", {
        operator: "Eq",
        value: this.selectedCountry
      });
    }
    this.crud.deleteQueryString("fromDate");
    this.crud.deleteQueryString("toDate");
    this.fetchList();
  },
  methods: {
    async onCountryChange(value) {
      const key = "Country";
      if (value === "All") {
        this.crud.deleteFilter(key);
        await this.crud.fetchList();
        return;
      }
      this.crud.setFilter(key, { operator: "Eq", value });
      await this.crud.fetchList();
    },
    async onDateRangeChange(value) {
      if (!value) {
        await this.crud.deleteQueryString("fromDate");
        await this.crud.deleteQueryString("toDate");
      } else {
        const { endDate, startDate } = value;
        const fromDate = this.$moment(startDate).clone().startOf("day").toISOString();
        const toDate = this.$moment(endDate).clone().endOf("day").toISOString();
        await this.crud.setQueryString("fromDate", fromDate);
        await this.crud.setQueryString("toDate", toDate);
      }
      await this.crud.fetchList();
    },
    getCountriesLists() {
      this.axios.get(`${apiUrl$1}/stuck-claims/common/region`).then((res) => {
        this.listCountries = [
          {
            description: "All",
            code: "All"
          },
          ...res.data
        ];
      }).catch((error) => {
        this.displayErrorNotification(error);
      });
    },
    parseTime(value) {
      const time = +value;
      const y = Math.floor(time / 31536e3);
      const mo = Math.floor(time % 31536e3 / 2628e3);
      const d = Math.floor(time % 31536e3 % 2628e3 / 86400);
      const h = Math.floor(time % (3600 * 24) / 3600);
      const m = Math.floor(time % 3600 / 60);
      const s = Math.floor(time % 60);
      const yDisplay = y > 0 ? y + (y === 1 ? " year, " : " years, ") : "";
      const moDisplay = mo > 0 ? mo + (mo === 1 ? " month, " : " months, ") : "";
      const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
      const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
      const mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes, ") : "";
      const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds ") : "";
      return yDisplay + moDisplay + dDisplay + hDisplay + mDisplay + sDisplay;
    },
    fetchList: _.debounce(async function() {
      this.isLoading = true;
      try {
        await this.crud.fetchList();
      } catch (error) {
        this.displayErrorNotification(error);
      } finally {
        this.isLoading = false;
      }
    }, 1e3)
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var TimeLog = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "stuck-claims.timelogs", "api-url": _vm.apiUrl } }, [_c("time-log")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { TimeLog },
  data() {
    return {
      apiUrl,
      page: STUCK_CLAIMS_TIME_LOG,
      itemsMenu: [
        {
          key: "administration",
          title: "Administration",
          path: ""
        },
        {
          key: "timelogs",
          title: "Time Log",
          path: "timelogs"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
